/*!
 * DisHarmonie Begin
 */
html {
  background: #91935E;
}

body {
  overflow-y: scroll;
}

header {
  position: relative;
}

.navbar {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
}

.top-bg {
  background: #333301 url(/assets/images/bg-top.png) top repeat-x;
}

.bottom-bg {
  background: url(/assets/images/bg-main.png) repeat-x top;
  min-height: 710px;
  padding-top: 30px;
}

.h1block {
  background: #9A9A6C;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 );
}

.h1block .h1, .h1block h1 {
  display: inline-block;
  color: white;
  margin: 0;
  padding: 10px 0;
  font-size: 30px;
}

.h1block .h1 small, .h1block h1 small {
  color: white;
}

dl.event dt {
  float: left;
  color: #ccc;
  clear: left;
  font-weight: normal;
  padding-right: 10px;
}

dl.event dd {
  float: left;
  font-weight: bold;
}

aside h2 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #ccc;
}

aside h3 {
  font-size: 16px;
}

aside ul.partners {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

aside ul.partners li {
  padding: 15px 0;
  text-align: center;
}

hr {
  border: none;
  background: none;
  border-top: 1px rgba(255, 255, 255, 0.5) dotted;
}

main a,
aside a {
  text-decoration: underline;
}

main > h2:nth-child(1) {
  margin-top: 0;
}

#afterfooter {
  padding-top: 15px;
  margin-top: 15px;
  padding-bottom: 20px;
  border-top: 1px rgba(255, 255, 255, 0.5) dotted;
  font-size: 10px;
}

.icon-star-empty {
  text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

.events {
  /*
  .description {
    padding:0 55px;
  }
  */
}

.events h2 {
  margin-top: 0;
  padding-top: 0;
  font-size: 24px;
}

.events h2 small {
  color: white;
}

.events h2 a {
  padding-right: 15px;
  text-decoration: none;
}

.events .events-list-item {
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.events .events-list-item img {
  float: left;
  margin-right: 25px;
  margin-bottom: 10px;
  max-width: 30%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  border-radius: 3px;
}

.events .events-list-item:hover img {
  box-shadow: 0 0 10px white;
}

.events .events-menu {
  padding-bottom: 10px;
}

.events .events-menu a.active {
  font-weight: bold;
}

h2 {
  position: relative;
}

h2 .icon-star-empty {
  position: absolute;
  left: -45px;
}

.guestbook .guestbook-form textarea {
  margin: 5px 0;
}

.guestbook .guestbook-post h2 {
  font-size: 20px;
}

.guestbook .guestbook-post h2 small {
  color: white;
}

.gallery {
  margin-top: 20px;
  margin-bottom: 20px;
}

.gallery a {
  display: block;
  margin-bottom: 20px;
}

.gallery a img {
  max-width: 100%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  border-radius: 3px;
}

.gallery a:hover img {
  box-shadow: 0 0 10px white;
}

body.xs header img {
  max-width: 100%;
}

body.xs nav {
  margin: 5px 0;
}

body.xs .navbar {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: static;
  border-radius: 4px;
}

/*!
 * DisHarmonie End
 */
